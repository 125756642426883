/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Los problemas que vemos actualmente en las centrales nucleares después del tsunami están poniendo en duda la seguridad de las centrales nucleares, aunque los políticos quieran hacernos creer que esto no es así.", React.createElement(_components.img, {
    src: "http://t1.gstatic.com/images?q=tbn:ANd9GcT-Q1jVEq_scgqJnMmoYVtrId64EAZHSQagLIAJZTuWrtCgdrXtbw",
    alt: ""
  }), " Se intentará mejorar la seguridad en las plantas nucleares europeas, pero todo esto no servirá de nada si no tenemos en cuenta el principal problema, el que han tenido las plantas japonesas, la falta de energía."), "\n", React.createElement(_components.p, null, "Como hemos visto estos días es importantísimo tener a mano fuentes de energía si ocurre una catástrofe, las fuentes de energía deben ser resistentes a estas catástrofes, situarse cerca de las centrales nucleares y tener un sistema que compruebe su estado continuamente y desvíe la energía hacia la planta nuclear si se da una alerta."), "\n", React.createElement(_components.p, null, "Una Smart Grid de renovables solucionaría estos problemas, tendría que estar situada cerca de la central y en su funcionamiento normal podría utilizarse para abastecer de energía limpia a la población."), "\n", React.createElement(_components.p, null, "Por otra parte los problemas que están sucediendo con las centrales nucleares podría poner en contra de las nucleares al pueblo japonés y que se empiece a invertir en renovables de una vez por todas. Pero para esto Japón tendría que salir de la crisis en la que lleva dos décadas metido y que se agravará con los efectos del tsunami."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
